.noteContainerHome {
  width: 80vw;
  margin: auto;
}

.noteHome {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding-top: 2em;
  justify-content: space-around;
}

.noteSearch {
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding-left: 1em;
  width: 40vw;
  height: 55vh;
}

.noteContainer {
  width: 40vw;
  height: 55vh;
  margin: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
}

.noteText:hover {
  background-color: rgba(220,220,220, 1);
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .noteSearch {
    display: flex;
    flex-direction: column;
    width: 20vw;
  }
}

@media screen and (max-width: 600px) {
  .noteHome {
    flex-direction: column;
  }

  .noteSearch {
    width: 80vw;
    text-align: center;
  }

  .inputField {
    margin: 0 auto;
    height: 3em;
    width: 200px;
  }

  .clearSearch {
    margin: 10px auto;
    height: 3em;
    width: 210px;
  }

  .noteContainer {
    width: 80vw;
  }
}

@media screen and (min-width: 1200) {
  .noteContainerHome {
    width: 60vw;
  }
}