.aboutHome {
  width: 80vw;
  margin: auto;
  height: 100vh;
}

.aboutText {
  width: 60%;
  margin: auto;
  padding-top: 30px;
}

.aboutLinks {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  padding-top: 50px;
  a {
    text-decoration: none;
    color: black;
    padding: 10px;
    width: 20px;
    margin: auto;
  }
}

.loginLink {
  position:fixed;
  padding: 20px;
  bottom:0;
  left:0;
  a {
    color: grey;
    text-decoration: none;
    padding: 10px;
  }
}

@media screen and (max-width: 950px) {
  .aboutLinks {
    a {
      color: grey;
    }
  }
}

@media screen and (min-width: 1200) {
  .aboutHome {
    width: 60vw;
  }
}