.bookDetailHomeContainer {
  width: 80vw;
  margin: auto;
}

.bookDetailContainer {
  text-align: center;
  height: 90vh;
  overflow: auto;
}

.commentContainer {
  text-align: left;
  width: 80%;
  margin: auto;

  b {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1200) {
  .bookDetailHomeContainer {
    width: 60vw;
  }
}