.challengeHomeContainer {
  width: 80vw;
  margin: auto;
}

.challengeCount {
  text-align: center;
  width: 70%;
  margin: 10px auto;
}

.challengeContainer {
  text-align: center;
  height: 60vh;
  overflow: auto;
  width: 50%;
  margin: auto;
}

.challengeDetail {
  margin-top: 5px;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 15px;
}

.challengeDetail:hover {
  background-color: rgba(220,220,220, 1);
}

.challengeBody {
  padding: 5px;
}

@media screen and (min-width: 1200) {
  .challengeHomeContainer {
    width: 60vw;
  }
}