.links {
  position:realive;
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin: auto;
  text-align: center;
  justify-content: space-around;

  a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
  }

  a:hover {
    color: grey;
  }
}

.activeLink {
  display: none;
}

@media screen and (max-width: 950px) {
  .fontLink {
    display: none;
  }
}